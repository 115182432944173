(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/floating-action-button/assets/javascripts/floating-action-button-container.js') >= 0) return;  svs.modules.push('/components/lb-ui/floating-action-button/assets/javascripts/floating-action-button-container.js');

'use strict';

const _excluded = ["children"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
const {
  FloatingActionButton
} = svs.components.lbUi.floatingActionButton;
const {
  createPortal
} = ReactDOM;
class FloatingActionButtonContainer extends React.PureComponent {
  render() {
    const _this$props = this.props,
      {
        children
      } = _this$props,
      props = _objectWithoutProperties(_this$props, _excluded);
    return createPortal(React.createElement(FloatingActionButton, props, children), document.body);
  }
}
setGlobal('svs.components.lbUi.floatingActionButton.FloatingActionButtonContainer', FloatingActionButtonContainer);

 })(window);